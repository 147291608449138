import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-508c2730"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column-id" }
const _hoisted_2 = { class: "p-column-title" }
const _hoisted_3 = { class: "speed-dials" }
const _hoisted_4 = { class: "dials" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.editingRows,
    dataKey: "pos",
    paginator: false,
    class: "p-datatable-sm",
    onRowReorder: _ctx.onRowReorder,
    showGridlines: "",
    scrollable: true,
    scrollHeight: "flex",
    editMode: "row",
    editingRows: _ctx.editingRows,
    "onUpdate:editingRows": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editingRows) = $event)),
    selection: _ctx.selectedRows,
    "onUpdate:selection": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedRows) = $event)),
    selectionMode: "multiple",
    onRowSelect: _ctx.onRowSelect,
    onRowUnselect: _ctx.onRowUnselect,
    onRowSelectAll: _ctx.onRowSelectAll,
    onRowUnselectAll: _ctx.onRowUnselectAll,
    metaKeySelection: false,
    resizableColumns: true,
    columnResizeMode: "fit",
    responsiveLayout: "scroll"
  }, {
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.Loading')), 1)
    ]),
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('Squeeze.General.NoEntriesFound', { entryName: _ctx.$t('Squeeze.Validation.General.Position') })), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", {
            class: _normalizeClass(_ctx.showButton ? 'pop-in' : 'pop-out')
          }, [
            (!_ctx.hideButtons)
              ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  type: "button",
                  icon: "mdi mdi-delete-sweep-outline",
                  class: "p-button-rounded p-button-danger p-shadow-18 speed-dials",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteSelectedRows())),
                  disabled: _ctx.selectedRows.length < 1
                }, null, 8, ["disabled"])), [
                  [
                    _directive_tooltip,
                    _ctx.$t('Squeeze.Validation.Buttons.DeleteSelectedRows'),
                    void 0,
                    { left: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("li", {
            class: _normalizeClass(_ctx.showButton ? 'pop-in' : 'pop-out')
          }, [
            (!_ctx.hideButtons)
              ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  type: "button",
                  icon: "mdi mdi-plus",
                  class: "p-button-rounded p-shadow-18 speed-dials",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.createNewRow(null)))
                }, null, 512)), [
                  [
                    _directive_tooltip,
                    _ctx.$t('Squeeze.Validation.Buttons.AddRow'),
                    void 0,
                    { left: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ], 2)
        ]),
        _withDirectives(_createVNode(_component_Button, {
          type: "button",
          class: "p-button-rounded p-shadow-18 speed-dials",
          icon: _ctx.showButton ? 'mdi mdi-table-arrow-down' : 'mdi mdi-table-arrow-up',
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showButton = !_ctx.showButton)),
          style: {"height":"3rem","width":"3rem"}
        }, null, 8, ["icon"]), [
          [
            _directive_tooltip,
            _ctx.$t('Squeeze.Validation.Buttons.TableOptions'),
            void 0,
            { left: true }
          ]
        ])
      ])
    ]),
    default: _withCtx(() => [
      (!_ctx.isReadOnlyMode)
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 0,
            rowReorder: true,
            headerClass: "header-center",
            style: _normalizeStyle([{"min-width":"4rem","text-align":"center"}, [
					_ctx.customTableActions.length > 2 ? (_ctx.customTableActions.length > 3 ? 'max-width: 7rem;' : 'max-width: 5.5rem;') : 'max-width: 4rem;',
					_ctx.store.state.userSettings.viewSettings.smallDistanceBetweenFields ? 'height: 2.438rem;' : ''
				]])
          }, _createSlots({ _: 2 }, [
            (_ctx.customTableActions.length > 0)
              ? {
                  name: "header",
                  fn: _withCtx(() => [
                    _createElementVNode("div", {
                      style: _normalizeStyle(_ctx.customTableActions.length >= 5 ? 'overflow-x: scroll;' : '')
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customTableActions, (action, index) => {
                        return _withDirectives((_openBlock(), _createBlock(_component_Button, {
                          key: index,
                          type: "button",
                          icon: action.icon ? action.icon : '',
                          class: _normalizeClass(["p-button-sm", index >= 1 ? (_ctx.customTableActions.length >= 5 ? 'p-ml-1 p-mb-1 p-mt-1' : 'p-ml-1') : (_ctx.customTableActions.length >= 5 ? 'p-mb-1 p-mt-1' : '')]),
                          style: {"min-width":"1.25rem","min-height":"1rem","padding":"0.15rem","width":"1.25rem"},
                          onClick: ($event: any) => (_ctx.executeCustomTableAction(action.id))
                        }, null, 8, ["icon", "class", "onClick"])), [
                          [_directive_tooltip, action.description]
                        ])
                      }), 128))
                    ], 4)
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["style"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Column, {
        selectionMode: "multiple",
        headerClass: "header-center",
        style: _normalizeStyle([{"min-width":"4rem","max-width":"4rem","text-align":"center"}, _ctx.store.state.userSettings.viewSettings.smallDistanceBetweenFields ? 'height: 2.438rem;' :  ''])
      }, null, 8, ["style"]),
      _createVNode(_component_Column, {
        field: "id",
        header: _ctx.$t('Squeeze.Validation.General.PositionHeadline'),
        style: _normalizeStyle([{"min-width":"4rem","max-width":"4rem"}, _ctx.store.state.userSettings.viewSettings.smallDistanceBetweenFields ? 'height: 2.438rem;' :  ''])
      }, {
        editor: _withCtx((slotProps) => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(slotProps.data["pos"]), 1),
          (slotProps.data.model.state === 'ERROR')
            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                key: 0,
                style: {"height":"1rem"},
                icon: "mdi mdi-alert-outline",
                class: "p-button p-button-rounded p-button-danger p-button-text"
              }, null, 512)), [
                [_directive_tooltip, _ctx.$t('Squeeze.Validation.General.ErrorCells')
				+ ' '
				+ slotProps.data.model.errorText]
              ])
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["header", "style"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allVisibleColumns, (col) => {
        return (_openBlock(), _createBlock(_component_Column, {
          key: col.id,
          field: col.name,
          headerClass: (col.dataType || '').toLowerCase() === 'amount' ? 'table-header-amount' : '',
          style: _normalizeStyle([{"min-width":"10rem"}, _ctx.store.state.userSettings.viewSettings.smallDistanceBetweenFields ? 'height: 2.438rem;' :  ''])
        }, {
          header: _withCtx(() => [
            _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createTextVNode(_toDisplayString(col.description), 1)
            ])), [
              [_directive_tooltip, col.description]
            ])
          ]),
          body: _withCtx((rows) => [
            _createTextVNode(_toDisplayString(rows.data.cells.find(cell => cell.columnName === col.name).value), 1)
          ]),
          editor: _withCtx((editingRows) => [
            (!_ctx.isReadOnlyMode && (col.dataType || '').toLowerCase() === 'text' && col.lookup && col.lookup.active === true && !col.readonly)
              ? (_openBlock(), _createBlock(_component_AutoComplete, {
                  key: 0,
                  type: "text",
                  modelValue: editingRows.data.cells[col.name].value,
                  "onUpdate:modelValue": ($event: any) => ((editingRows.data.cells[col.name].value) = $event),
                  minLength: col.lookup.minInputLength,
                  suggestions: _ctx.filteredValues[col.name],
                  field: "label",
                  valueField: "value",
                  class: _normalizeClass(["input-table input-autocomplete", {
							'table-cell-border-by-error': editingRows.data.cells[col.name].state === 'ERROR',
							'table-cell-border-by-force': editingRows.data.cells[col.name].state === 'FORCEAPPROVAL',
							'table-cell-border-by-ok': editingRows.data.cells[col.name].state === 'OK',
							'readonly': col.readonly || _ctx.isReadOnlyMode,
						}]),
                  inputClass: {
							'validation-field': _ctx.store.state.userSettings.viewSettings.smallDistanceBetweenFields === true
						},
                  onKeydown: [
                    _withKeys(($event: any) => (_ctx.navigateCells($event, editingRows.data, col, editingRows.data.cells[col.name])), ["enter","tab","up","down","left","right"]),
                    ($event: any) => (_ctx.onKeydown($event, editingRows.data, col))
                  ],
                  onBlur: _ctx.onFieldChange,
                  ref_for: true,
                  ref: el => { _ctx.setCellReference(el, editingRows.data, col) },
                  forceSelection: !col.lookup.allowCustomValues,
                  readonly: col.readonly || _ctx.isReadOnlyMode,
                  onComplete: ($event: any) => (_ctx.searchAutocomplete($event, col, editingRows.data)),
                  onItemSelect: ($event: any) => (_ctx.onItemSelect($event, col, editingRows.data, editingRows.data.cells[col.name])),
                  onFocus: ($event: any) => (_ctx.onFocusField($event, editingRows.data.cells[col.name], editingRows.data, col)),
                  dropdownMode: 'current',
                  onClick: ($event: any) => (_ctx.onClickAutocomplete($event, editingRows.data, col)),
                  "auto-highlight": true,
                  dropdown: (!col.readonly && col.lookup.minInputLength === 0) && !_ctx.isReadOnlyMode
                }, null, 8, ["modelValue", "onUpdate:modelValue", "minLength", "suggestions", "class", "inputClass", "onKeydown", "onBlur", "forceSelection", "readonly", "onComplete", "onItemSelect", "onFocus", "onClick", "dropdown"]))
              : (_openBlock(), _createBlock(_component_InputText, {
                  key: 1,
                  class: _normalizeClass(["input-table", {
							'validation-field': _ctx.store.state.userSettings.viewSettings.smallDistanceBetweenFields === true,
							'table-cell-amount': ((col.dataType || '').toLowerCase() === 'amount'),
							'table-cell-border-by-error': editingRows.data.cells[col.name].state === 'ERROR',
							'table-cell-border-by-force': editingRows.data.cells[col.name].state === 'FORCEAPPROVAL',
							'table-cell-border-by-ok': editingRows.data.cells[col.name].state === 'OK',
							'readonly': col.readonly || _ctx.isReadOnlyMode,
						}]),
                  modelValue: editingRows.data.cells[col.name].value,
                  "onUpdate:modelValue": ($event: any) => ((editingRows.data.cells[col.name].value) = $event),
                  onKeydown: _withKeys(($event: any) => (_ctx.navigateCells($event, editingRows.data, col, editingRows.data.cells[col.name])), ["enter","tab","up","down","left","right"]),
                  onChange: _ctx.onFieldChange,
                  onFocus: ($event: any) => (_ctx.onFocusField($event, editingRows.data.cells[col.name], editingRows.data, col)),
                  ref_for: true,
                  ref: el => { _ctx.setCellReference(el, editingRows.data, col) },
                  readonly: col.readonly || _ctx.isReadOnlyMode
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "onChange", "class", "onFocus", "readonly"]))
          ]),
          _: 2
        }, 1032, ["field", "headerClass", "style"]))
      }), 128)),
      _createVNode(_component_Column, {
        style: _normalizeStyle([{"min-width":"5rem","max-width":"5rem","text-align":"end"}, _ctx.store.state.userSettings.viewSettings.smallDistanceBetweenFields ? 'height: 2.438rem;' :  ''])
      }, {
        editor: _withCtx((slotProps) => [
          (!_ctx.hideButtons)
            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                key: 0,
                icon: "mdi mdi-delete-outline",
                class: "p-button p-button-rounded p-button-danger p-button-text",
                onClick: ($event: any) => (_ctx.deleteRow(slotProps.data.pos))
              }, null, 8, ["onClick"])), [
                [_directive_tooltip, _ctx.$t('Squeeze.Validation.Buttons.Delete')]
              ])
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["style"])
    ]),
    _: 1
  }, 8, ["value", "onRowReorder", "editingRows", "selection", "onRowSelect", "onRowUnselect", "onRowSelectAll", "onRowUnselectAll"]))
}